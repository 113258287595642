import React, { ReactNode, useEffect, useState, createContext, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ConfigurationService } from "../services/configuration.service";
import { useLeftNavigationStore } from "../stores/navigation.store";
import { useHeaderStore } from "../stores/header.store";
import Loading from "../components/Loading";
import ErrorFallback from "../components/errors/ErrorFallback";
import { TranslationService } from "../services/i18n.service";
import {
  LeftNavigationMenuItems,
  ProgressBarStyle,
  TroubleshootGuideTypes,
} from "../constants/index";
import { GlobalStyles } from "@mui/system";
import useGuideStore from "../components/TroubleShootGuide/guide.store";

interface ConfigurationProviderProps {
  children: ReactNode;
}

const updateFavicon = (faviconUrl: string) => {
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = faviconUrl || "/favicon.ico";
};

const ConfigurationContext = createContext({});

const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({
  children,
}) => {
  const [error, setError] = useState<any>();
  const [initialized, setInitialized] = useState(false);
  const [theme, setTheme] = useState<any>();
  const { initialize: initNavigation } = useLeftNavigationStore(
    (state) => state
  );
  const { setGuideContent } = useGuideStore((state) => state);
  const { logo } = useLeftNavigationStore((state) => state);

  const { setProgressBarType } = useHeaderStore((state) => state);

  useEffect(() => {
    const init = async () => {
      try {
        if (initialized) return;
        const configuration = await ConfigurationService.init();
        await TranslationService.init(configuration.translations);
        initNavigation({
          logo: configuration.config.logo || logo,
          navigationStates: { default: configuration.menuItems },
          dashboardPermissions:
            configuration?.menuItems?.find(
              (item) => item.id === LeftNavigationMenuItems.STUDIESDASHBOARD
            )?.permissions || [],
        });
        setProgressBarType(
          configuration.config?.heading?.style ||
            ProgressBarStyle.LINEAR_PROGRESS
        );
        if (configuration.troubleshootGuide) {
          setGuideContent(
            TroubleshootGuideTypes.APPLICATION,
            configuration.troubleshootGuide
          );
        }
      } catch (err) {
        const error = new Error("error.fetch.config", { cause: err });
        setError(error);
      } finally {
        const configuration = ConfigurationService.configuration;
        updateFavicon(configuration.config.favicon);
        setTheme(configuration.theme);
        setInitialized(true);
      }
    };
    init();
  });

  if (!initialized) {
    return <Loading />;
  }

  const appTheme = createTheme(theme);
  const config = {}; // Add your configuration logic here

  return (
    <ConfigurationContext.Provider value={config}>
      <ThemeProvider theme={appTheme}>
        <GlobalStyles styles={{ body: { margin: 0 } }} />
        {error ? (
          <ErrorFallback resetErrorBoundary={(): void => {}} error={error} />
        ) : (
          children
        )}
      </ThemeProvider>
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => useContext(ConfigurationContext);

export default ConfigurationProvider;
