import { ReactNode, useEffect, lazy, Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useParams,
  useLocation,
  matchPath,
} from "react-router-dom";
import { AuthenticationProvider } from "providers/authentication.provider";
import PageContainer from "layouts/PageContainer";
import ErrorBoundaryWrapper from "components/errors/ErrorBoundaryWrapper";
import NotFound from "components/errors/NotFound";
import DashboardProtectedRoute from "./DashboardProtectedRoute";
import SystemCheckProvider from "providers/systemCheck.provider";
import { usePurgeStore } from "./PurgeDevice/purge.store";
import { useResultStore } from "./Result/result.store";
import { ProgressBarStatus } from "stores/header.store";
import { ROUTES } from "constants/routes.constants";
import Loading from "components/Loading";
const TrialsManagement = lazy(() => import("pages/TrialsManagement"));
const Devices = lazy(() => import("pages/Devices"));
const Experiment = lazy(() => import("pages/Experiment"));
const Result = lazy(() => import("pages/Result"));
const PurgeDevice = lazy(() => import("pages/PurgeDevice"));
const Participants = lazy(
  () => import("pages/Participants/Participants.controller")
);
const StudiesDashboard = lazy(() => import("pages/StudiesDashboard"));
const StudyOverview = lazy(() => import("./StudiesDashboard/StudyOverview"));
const StudyParticipants = lazy(
  () => import("./StudiesDashboard/StudyParticipants")
);
const StudyDevices = lazy(() => import("./StudiesDashboard/StudyDevices"));
const ParticipantDashboard = lazy(() =>
  import("./ParticipantDashboard/ParticipantDashboard").then((module) => ({
    default: module.ParticipantDashboard,
  }))
);

interface RouteWrapperProps {
  element: ReactNode;
}

type RouteParams = {
  trialId?: string;
  pui?: string;
  annotationId?: string;
};

function RouteWrapper({ element }: RouteWrapperProps) {
  const params: RouteParams = useParams();
  const location = useLocation();
  const resetPurgeStore = usePurgeStore((state) => state.reset);
  const purgeStoreStatus = usePurgeStore((state) => state.status);
  const resetResultStore = useResultStore((state) => state.reset);
  const resultStoreEvents = useResultStore((state) => state.events);

  useEffect(() => {
    const isPurgePage = matchPath(ROUTES.DEVICES_PURGE, location.pathname);
    const isResultPage = matchPath(
      ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
      location.pathname
    );
    if (!isPurgePage && purgeStoreStatus !== ProgressBarStatus.PENDING)
      resetPurgeStore();
    if (!isResultPage && resultStoreEvents.length) resetResultStore();
  }, [location]);

  return (
    <ErrorBoundaryWrapper>
      <PageContainer routeParams={params}>{element}</PageContainer>
    </ErrorBoundaryWrapper>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ROUTES.TRIALS_MANAGEMENT} />,
  },
  {
    path: ROUTES.TRIALS_MANAGEMENT,
    element: <RouteWrapper element={<TrialsManagement />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
    element: <RouteWrapper element={<Devices />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
    element: <RouteWrapper element={<Experiment />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
    element: <RouteWrapper element={<Result />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANTS,
    element: <RouteWrapper element={<Participants />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANT_DETAILS,
    element: <RouteWrapper element={<TrialsManagement />} />,
  },
  {
    path: ROUTES.TRIAL_PARTICIPANT_DETAILS_SUMMARY,
    element: <RouteWrapper element={<TrialsManagement />} />,
  },
  {
    path: ROUTES.TRIAL_DETAILS,
    element: <RouteWrapper element={<TrialsManagement />} />,
  },
  {
    path: ROUTES.DEVICES_PURGE,
    element: <RouteWrapper element={<PurgeDevice />} />,
  },
  {
    path: "/",
    element: <RouteWrapper element={<TrialsManagement />} />,
  },
  {
    path: ROUTES.STUDIES_DASHBOARD,
    element: <DashboardProtectedRoute />,
    children: [
      {
        path: ROUTES.STUDIES_DASHBOARD,
        element: <RouteWrapper element={<StudiesDashboard />} />,
      },
      {
        path: ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW,
        element: <RouteWrapper element={<StudyOverview />} />,
      },
      {
        path: ROUTES.STUDIES_DASHBOARD_TRIAL_DETAILS,
        element: <RouteWrapper element={<StudiesDashboard />} />,
      },
      {
        path: ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS,
        element: <RouteWrapper element={<StudyParticipants />} />,
      },
      {
        path: ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES,
        element: <RouteWrapper element={<StudyDevices />} />,
      },
    ],
  },
  {
    path: ROUTES.PARTICIPANT_DASHBOARD,
    element: <DashboardProtectedRoute />,
    children: [
      {
        path: ROUTES.PARTICIPANT_DASHBOARD,
        element: <RouteWrapper element={<ParticipantDashboard />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <NotFound
        error={{
          statusCode: 404,
          message: "error.fallback.notfound.title",
        }}
      />
    ),
  },
]);

function AppRoutes() {
  return (
    <AuthenticationProvider>
      <SystemCheckProvider>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </SystemCheckProvider>
    </AuthenticationProvider>
  );
}

export default AppRoutes;
