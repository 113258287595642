import React from "react";

import Snackbar from "./Snackbar/Snackbar.component";

export default function Services() {
  return (
    <>
      <Snackbar />
    </>
  );
}
