import React, { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { SxProps, Theme, useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import {
  BreathIcon,
  HumidityIcon,
  TemperatureIcon,
  PendingIcon,
  SuccessIcon,
  FailIcon,
  WarningIcon,
  GearIcon,
  CloudIcon,
  SensorIcon,
  ConnectivityIcon,
  ProgressIcon,
  AnalyticsIcon,
  FinishIcon,
  RecoveryIcon,
  BaselineIcon,
  NumberIcon,
} from "components/icons";
import LoadingIcon from "components/Loading.component";
import { StyledGrid } from "./styles";
import { Typography } from "@mui/material";
import { getAssetsPath } from "utils/assets.utils";
import SvgImage from "components/SvgImage.component";

export enum CardSize {
  small = "small",
  medium = "medium",
  large = "large",
  larger = "larger",
  fullWidth = "fullWidth",
  fixed = "fixed",
  xl = "xl",
}

export enum CardStatus {
  INFO = "INFO",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  WARNING = "WARNING",
}

export enum CardBackgrounds {
  breath = "breath",
  humidity = "humidity",
  temperature = "temperature",
  gear = "gear",
  cloud = "cloud",
  sensor = "sensor",
  connection = "connection",
  progress = "progress",
  analytics = "analytics",
  baseline = "baseline",
  recovery = "recovery",
  finish = "finish",
  none = "none",
}

interface NumberIconProps {
  show: boolean;
  label?: string | number;
  color: string;
  background: string;
}

export interface CardProps {
  label?: string;
  labelColor?: string;
  background?: CardBackgrounds | string;
  status?: CardStatus;
  size?: CardSize;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  showIcon?: boolean;
  step?: NumberIconProps;
  backgroundIconColor?: string;
  iconStyle?: SxProps<Theme>;
}

export const getCardColor = (status: CardStatus, theme: Theme) => {
  switch (status) {
    case CardStatus.INFO:
      return theme.palette.info;
    case CardStatus.PENDING:
      return theme.palette.pending;
    case CardStatus.IN_PROGRESS:
      return theme.palette.progress;
    case CardStatus.SUCCESS:
      return theme.palette.success;
    case CardStatus.FAIL:
      return theme.palette.error;
    case CardStatus.WARNING:
      return theme.palette.warning;
    default:
      return theme.palette.pending;
  }
};

export const getFiltersColor = (status: CardStatus, theme: Theme) => {
  switch (status) {
    case CardStatus.INFO:
      return theme.palette.info;
    case CardStatus.PENDING:
      return {
        main: theme.palette.grey[400],
        dark: theme.palette.grey[500],
        lighter: theme.palette.grey[200],
      };
    case CardStatus.IN_PROGRESS:
      return theme.palette.progress;
    case CardStatus.SUCCESS:
      return theme.palette.success;
    case CardStatus.FAIL:
      return theme.palette.error;
    case CardStatus.WARNING:
      return theme.palette.warning;
    default:
      return theme.palette.pending;
  }
};

const getBackgroundIconSize = (size: CardSize) => {
  switch (size) {
    case CardSize.small:
      return "4rem";
    case CardSize.medium:
      return "6rem";
    case CardSize.large:
      return "10rem";
    case CardSize.larger:
      return "10rem";
    case CardSize.fixed:
      return "7.5rem";
    case CardSize.fullWidth:
      return "10rem";
    default:
      return "10rem";
  }
};

const getBackground = (
  background: CardBackgrounds | string | undefined,
  color: string,
  size: CardSize = CardSize.large
): string | ReactNode | null => {
  if (!background) return null;
  if (background.startsWith("icons/")) {
    return (
      <SvgImage
        url={getAssetsPath(background)}
        primaryColor={color}
        sx={{
          width: getBackgroundIconSize(size),
          height: getBackgroundIconSize(size),
          objectFit: "scale-down",
        }}
      />
    );
  }
  switch (background) {
    case CardBackgrounds.breath:
      return (
        <BreathIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.humidity:
      return (
        <HumidityIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.temperature:
      return (
        <TemperatureIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.gear:
      return (
        <GearIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.cloud:
      return (
        <CloudIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.sensor:
      return (
        <SensorIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.connection:
      return (
        <ConnectivityIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.progress:
      return (
        <ProgressIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.analytics:
      return (
        <AnalyticsIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.finish:
      return (
        <FinishIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.recovery:
      return (
        <RecoveryIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.baseline:
      return (
        <BaselineIcon
          sx={{
            color,
            fontSize: getBackgroundIconSize(size),
          }}
        />
      );
    case CardBackgrounds.none:
      return null;
    default:
      return background;
  }
};

export const getIcon = (
  status: CardStatus,
  theme: Theme,
  color: string,
  size: CardSize = CardSize.large,
  step?: NumberIconProps
) => {
  return step && step.show ? (
    <NumberIcon
      color={step.color}
      background={step.background}
      label={step?.label}
    />
  ) : (
    (() => {
      switch (status) {
        case CardStatus.IN_PROGRESS:
          return (
            <LoadingIcon
              sx={{
                width: "3.3rem",
                height: "auto",
                fill: theme.palette.success.main,
              }}
            />
          );
        case CardStatus.SUCCESS:
          return <SuccessIcon sx={{ fill: color, fontSize: "3rem" }} />;
        case CardStatus.FAIL:
          return <FailIcon sx={{ fill: color, fontSize: "3rem" }} />;
        case CardStatus.WARNING:
          return <WarningIcon sx={{ fill: color, fontSize: "3rem" }} />;
        case CardStatus.PENDING:
        default:
          return <PendingIcon sx={{ color: color, fontSize: "3rem" }} />;
      }
    })()
  );
};

export const getCardSize = (size: CardSize) => {
  const cardSizes: { [key in CardSize]: SxProps<Theme> } = {
    small: { height: "7rem" },
    medium: { height: "7rem" },
    large: { height: "15rem" },
    larger: { height: "15rem" },
    fullWidth: { height: "15rem" },
    fixed: { height: "9rem", minWidth: { sm: "15rem" } },
    xl: { height: "32rem" },
  };
  return cardSizes[size];
};

const Card: React.FC<CardProps> = ({
  label = undefined,
  labelColor = undefined,
  status,
  background,
  size,
  children,
  sx,
  step = undefined,
  showIcon = false,
  backgroundIconColor = undefined,
  iconStyle = undefined,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const _status = status || CardStatus.PENDING;
  const cardColor = getCardColor(_status, theme);
  return (
    <StyledGrid
      sx={{
        ...getCardSize(size || CardSize.large),
        // @ts-ignore
        border: `1px solid ${cardColor.main}`,
        color: cardColor.dark,
        backgroundColor: ![
          CardStatus.IN_PROGRESS,
          CardStatus.WARNING,
          CardStatus.FAIL,
        ].includes(status as CardStatus)
          ? status === CardStatus.SUCCESS
            ? cardColor.lighter
            : cardColor.light
          : "transparent",
        ...sx,
      }}
    >
      {showIcon && (
        <Box
          sx={{
            position: "absolute",
            left: "0.5rem",
            top: "2.5rem",
            ...iconStyle,
          }}
        >
          {getIcon(_status, theme, cardColor.main, size, step)}
        </Box>
      )}
      {background && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            opacity: 0.1,
          }}
        >
          {getBackground(
            background,
            backgroundIconColor ? backgroundIconColor : cardColor.dark,
            size
          )}
        </Box>
      )}
      {label && (
        <Typography
          component="span"
          sx={{
            fontSize: "1.25rem",
            color: labelColor ? labelColor : cardColor.dark,
          }}
        >
          {t(label)}
        </Typography>
      )}
      {children}
    </StyledGrid>
  );
};

export default Card;
