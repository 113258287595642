import { create, StoreApi } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ConfigurationsStore {
  version: number | null;
  setVersion: (version: number | null) => void;
  configuration: Record<string, unknown>;
  setConfiguration: (configuration: Record<string, unknown>) => void;
}

const stores: Record<string, StoreApi<ConfigurationsStore>> = {};

const useConfigurationsStore = (
  configurationId: string
): StoreApi<ConfigurationsStore> => {
  if (!stores[configurationId]) {
    stores[configurationId] = create<ConfigurationsStore>()(
      persist(
        (set) => ({
          version: null,
          setVersion: (version) => set({ version }),
          configuration: {},
          setConfiguration: (configuration) => set({ configuration }),
        }),
        {
          name: configurationId,
          storage: createJSONStorage(() => localStorage),
        }
      )
    );
  }
  return stores[configurationId];
};

export default useConfigurationsStore;
