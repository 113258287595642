import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, List } from "@mui/material";
import LeftNavigationItems from "./LeftNavigationItems";
import {
  openedMixin,
  closedMixin,
  drawerWidth,
  DrawerHeader,
} from "layouts/layouts.styles";
import { useIsMobile, useIsTablet } from "hooks/media.hook";
import { StyleIconButton } from "./layouts.styles";
import { getAssetsPath } from "utils/assets.utils";
import { ConfigurationService } from "services/configuration.service";
import { useLeftNavigationStore } from "stores/navigation.store";
import LeftNavigationBottomLinks from "./LeftNavigationBottomLinks";

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isTablet",
})<{
  open: boolean;
  isTablet: boolean;
}>(({ theme, open, isTablet }) => ({
  width: isTablet ? 0 : drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...(!isTablet && openedMixin(theme)),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface LeftNavigationProps {
  currentLanguage: string;
}

const LeftNavigation: React.FC<LeftNavigationProps> = () => {
  const configurationData = ConfigurationService.configuration;
  const isMobile = useIsMobile();
  const open = useLeftNavigationStore((state) => state.open);
  const setOpen = useLeftNavigationStore((state) => state.setOpen);
  const isTablet = useIsTablet();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <StyleIconButton
        onClick={handleDrawerToggle}
        sx={{
          display: open ? "none" : "inherit",
          left: open ? 0 : "0.5rem",
        }}
      >
        <img src={getAssetsPath("icons/burger_right.svg")} alt="Close" />
      </StyleIconButton>
      <DrawerStyled
        transitionDuration={configurationData.config.drawerTransitionDuration}
        variant="permanent"
        open={open}
        isTablet={isTablet}
        sx={{
          border: "none",
          display: isMobile ? (open ? "block" : "none") : "inherit",
        }}
      >
        <DrawerHeader>
          <StyleIconButton
            onClick={handleDrawerToggle}
            sx={{
              opacity: open ? 1 : 0,
            }}
          >
            <img src={getAssetsPath("icons/burger_left.svg")} alt="Close" />
          </StyleIconButton>
        </DrawerHeader>
        <LeftNavigationItems open={open} />
        <List>
          <LeftNavigationBottomLinks open={open} />
        </List>
      </DrawerStyled>
    </Box>
  );
};

export default LeftNavigation;
