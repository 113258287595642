import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StyledTextLabelTitle } from "components.styles";

const TextLabel = ({
  label,
  required,
  variant,
}: {
  label: string;
  required?: boolean;
  variant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "body2"
    | "body1"
    | "subtitle1"
    | "subtitle2"
    | "overline"
    | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <StyledTextLabelTitle variant={variant ? variant : "inherit"}>
      {t(label)}
      {required ? (
        <Typography
          component={"span"}
          sx={{ color: "red", marginLeft: "0.2rem" }}
        >
          *
        </Typography>
      ) : null}
    </StyledTextLabelTitle>
  );
};

export default TextLabel;
