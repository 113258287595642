import { AnnotationConfiguration } from "services/annotation.service";
import { TrialDetail } from "services/cde.service";
import { FieldConfig } from "utils/form.utils";
import { create } from "zustand";
import { useLeftNavigationStore } from "./navigation.store";
import useReportIssueStore from "components/ReportIssue/reportIssue.store";
import { PermissionTypes } from "constants/index";

export type AliasConfig = {
  name: {
    show: boolean;
  };
  serialNumber: {
    show: boolean;
  };
};

export enum AliasFields {
  NAME = "name",
  SERIALNUMBER = "serialNumber",
}

interface TrialConfig {
  trialConfiguration: AnnotationConfiguration | undefined;
  otherFields: Record<string, any>;
  setTrialData: (data: Record<string, any>) => void;
  trialData: TrialDetail;
  getTrialConfiguration: () => AnnotationConfiguration | undefined;
  getField: (field: string) => any;
  getFormFields: (formName: string) => FieldConfig[];
  getTranslationsId: () => string | undefined;
  getSerialNumbers: () => string[];
  getTrialName: () => string;
  getMinExperimentPerParticipant: () => number;
  hasViewMertricsPermission: () => boolean;
  getDevices: () => [];
  getAliasData: () => AliasConfig;
}

const useTrialStore = create<TrialConfig>((set, get) => ({
  trialConfiguration: undefined,
  otherFields: {},
  trialData: {} as TrialDetail,
  setTrialData: (data) =>
    set((state) => {
      const { configuration, ...rest } = data;
      const serialNumbers = data.serialNumbers || [];
      useReportIssueStore.getState().setSerialNumbers(serialNumbers);

      return {
        trialConfiguration: configuration
          ? configuration
          : state.trialConfiguration,
        otherFields: { ...state.otherFields, ...rest },
        TrialDetail: data as TrialDetail,
      };
    }),
  getTrialConfiguration: () => {
    const state = get();
    return state.trialConfiguration || undefined;
  },
  getField: (field) => {
    const state = get();
    return state.otherFields[field];
  },
  getFormFields: (formName) => {
    const state = get();
    return (
      state.trialConfiguration?.data?.forms?.[formName as any]?.fields || []
    );
  },
  getTranslationsId: () => {
    const state = get();
    return state.trialConfiguration?.data?.translations;
  },
  getSerialNumbers: () => {
    const state = get();
    return state.otherFields["serialNumbers"] || [];
  },
  getDevices: () => {
    const state = get();
    return state.otherFields["devices"] || [];
  },
  getTrialName: () => {
    const state = get();
    return state.otherFields["name"];
  },
  getMinExperimentPerParticipant: () => {
    const state = get();
    return (
      state.trialConfiguration?.data?.options?.participants?.minExperiments || 5
    );
  },
  hasViewMertricsPermission: () => {
    const state = get();
    const cdeId = state?.getField("cdeId");
    const cdePermissions = useLeftNavigationStore.getState().cdePermissions;
    return cdePermissions.some(
      (cde) =>
        cde.cdeId === cdeId &&
        [PermissionTypes.OWNER, PermissionTypes.ADMIN].includes(
          cde.permission as PermissionTypes
        )
    );
  },
  getAliasData: () => {
    const state = get();
    const _annotationConfiguration = state.trialConfiguration || undefined;
    if (!_annotationConfiguration) return;
    const trialDetails = _annotationConfiguration.data;
    return (
      trialDetails.pages.devices?.aliasConfig?.fields || {
        serialNumber: {
          show: true,
        },
        name: { show: false },
      }
    );
  },
}));

export default useTrialStore;
