import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ConfigurationProvider from "./providers/configuration.provider";
import ErrorBoundaryWrapper from "./components/errors/ErrorBoundaryWrapper";
import AppRoutes from "./pages/AppRoutes";

import "./fonts.css";
import Services from "./serviceComponents/Services.component";

const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundaryWrapper>
      <QueryClientProvider client={queryClient}>
        <ConfigurationProvider>
          <AppRoutes />
          <Services />
          <ReactQueryDevtools initialIsOpen={false} />
        </ConfigurationProvider>
      </QueryClientProvider>
    </ErrorBoundaryWrapper>
  );
}

export default App;
