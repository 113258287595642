import { Box, IconProps } from "@mui/material";

const LoadingIcon: React.FC<IconProps> = ({ sx }) => {
  const fillColor = (sx as any)?.fill || "#36b37e";
  return (
    <Box component="div" sx={sx}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 110 110"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle r="45" cx="55" cy="55" fill={fillColor} />
        <circle
          r="20"
          cx="55"
          cy="55"
          fill="none"
          stroke="#fff"
          strokeWidth="40"
          strokeDasharray="158 158"
          strokeDashoffset="158"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="158"
            to="0"
            dur="3s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </Box>
  );
};

export default LoadingIcon;
