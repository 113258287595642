import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import {
  TrialDetail,
  fetchTrialDetail,
  fetchParticipantsList,
  createParticipant as _createParticipant,
  fetchParticipantById,
} from "services/cde.service";

import { TranslationService } from "services/i18n.service";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { ConfigurationService } from "services/configuration.service";
import { CustomError } from "utils/privateHttp.utils";
import { SortOrder, TroubleshootGuideTypes } from "constants/index";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import useConfigurationsStore from "stores/configurations.store";

export interface Options {
  trialId?: string;
  take?: number;
  skip?: number;
  order?: string;
  name?: string;
  pui?: string;
  participants?: boolean;
  annotations?: boolean;
  expand?: string;
  trialMemberId?: string;
}

interface TrialData extends TrialDetail {
  configuration: any;
  serialNumbers: string[];
}

function useTrialApi(initialOptions: Options) {
  const setGuideContent = useGuideStore((state) => state.setGuideContent);
  const [options, setOptions] = useState<Options>(initialOptions);
  const queryClient = useQueryClient();
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const configurationStore = useConfigurationsStore(options.trialId || "");
  const { configuration, version } = configurationStore.getState();

  const setVersion = configurationStore.getState().setVersion;
  const setConfiguration = configurationStore.getState().setConfiguration;

  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const trial = useQuery({
    queryKey: [QueryKeys.TRIAL, options.trialId],
    queryFn: async (): Promise<TrialData> => {
      try {
        const isInitialFetch = !version;
        const result = await fetchTrialDetail(
          options.trialId || "",
          isInitialFetch
        );

        if (!version || version !== result.configurationVersion) {
          const latestConfiguration = !version
            ? result.configuration
            : await ConfigurationService.fetchConfigurationById(
                result.configurationId
              );
          setVersion(result.configurationVersion);
          setConfiguration(latestConfiguration);
          result.configuration = latestConfiguration;
        } else if (Object.keys(configuration).length) {
          result.configuration = configuration;
        }

        if (!Object.keys(result.configuration).length) {
          throw new Error("Annotation configuration fetch failed");
        }

        const { data } = result.configuration;

        if (data?.forms && typeof data?.forms === "string") {
          const formConfiguration =
            await ConfigurationService.fetchConfigurationById(data.forms);
          result.configuration.data.forms = formConfiguration.data;
        }

        const localizationsUuid = data?.translations;
        const locales = await ConfigurationService.fetchTranslationData(
          localizationsUuid
        );
        await TranslationService.updateTranslationFromAnnotationConfig(locales);

        let troubleshootGuide = data?.guides;
        if (data?.guides && typeof data?.guides === "string") {
          troubleshootGuide = (
            await ConfigurationService.fetchConfigurationById(data?.guides)
          )?.data;
        }
        if (troubleshootGuide) {
          setGuideContent(TroubleshootGuideTypes.STUDY, troubleshootGuide);
        }
        return {
          ...result,
          serialNumbers: result.serialNumbers,
        };
      } catch (error: any) {
        httpErrorNotification(error);
        throw error;
      }
    },
    enabled: options.annotations ?? !!options.trialId,
  });

  const refreshTrial = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TRIAL, options.trialId],
    });
  };

  const createParticipant = useMutation({
    mutationFn: async (data: any) => await _createParticipant(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_PARTICIPANTS],
      });
      successNotification("participant.created.success");
    },

    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  const participants = useQuery({
    queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId, options],
    queryFn: async () =>
      await fetchParticipantsList(
        options.trialId || "",
        { pui: options.pui },
        options.take || 0,
        options.skip || 0,
        options.expand,
        options.order || `createdAt|${SortOrder.DESC}`
      ),
    enabled: options.participants ?? !!options.trialId,
  });

  const participant = useQuery({
    queryKey: [
      QueryKeys.TRIAL_PARTICIPANT,
      options.trialId,
      options.trialMemberId,
    ],
    queryFn: async () => {
      return await fetchParticipantById(
        options.trialId || "",
        options.trialMemberId || ""
      );
    },
    enabled: !!options.trialMemberId,
  });

  return {
    trial,
    refreshTrial,
    participants,
    participant,
    setOptions,
    options,
    createParticipant,
  };
}

export default useTrialApi;
