import { buildLocalUrl } from "utils/url.utils";
import { ITitleItem } from "components/HeadingTitle";
import { ProgressBarStyle } from "constants/index";
import { create } from "zustand";
import {
  HeadingConfiguration,
  IndicatorValidation,
} from "services/annotation.service";

export enum ProgressBarStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  WARNING = "WARNING",
}

export interface ProgressBarStep {
  label: string;
  status: ProgressBarStatus;
  link?: string;
  enableLink?: boolean;
}

export interface Params {
  key: string;
  value: string;
}

export const headingValidationInit = {
  headingValidation: { signalStrength: {} },
};

export interface InitProgressBarOptions {
  steps: ProgressBarStep[];
  activeStep?: number;
  allowAction?: boolean;
  onClick?: (link: string, label: string) => void;
}
export interface InitHeaderOptions {
  mainTitle: ITitleItem;
  subTitleItems?: ITitleItem[];
  showMenu?: boolean;
  showBackButton?: boolean;
  showOptionsMenu?: boolean;
  progressBarType?: ProgressBarStyle;
  signalIndicator?: {
    heading: HeadingConfiguration | undefined;
    validation: IndicatorValidation;
  };
}
interface HeaderState {
  steps: ProgressBarStep[];
  activeStep: number;
  allowAction: boolean;
  onClick?: (link: string, label: string) => void;

  mainTitle: ITitleItem;
  subTitleItems?: ITitleItem[];
  signalIndicator?: {
    heading: HeadingConfiguration | undefined;
    validation: IndicatorValidation;
  };
  showMenu: boolean;
  showBackButton: boolean;
  showOptionsMenu: boolean;
  progressBarType?: ProgressBarStyle;

  initializeProgressBar: (
    options: {
      steps: ProgressBarStep[];
      activeStep?: number;
      allowAction?: boolean;
      onClick?: (link: string, label: string) => void;
    },
    params?: Params[]
  ) => void;
  initializeHeader: (options: InitHeaderOptions) => void;
  setAllowAction: (allowAction: boolean) => void;
  setStepStatus: (stepId: number, state: ProgressBarStatus) => void;
  setActiveStep: (activeStep: number) => void;
  populateLinks: (params: Params[]) => void;
  setShowBackButton: (showBackButton: boolean) => void;
  setShowOptionsMenu: (showOptionsMenu: boolean) => void;
  setProgressBarType: (progressBarType: ProgressBarStyle) => void;
  showDivider?: boolean;
  initialize: (
    headerOptions: InitHeaderOptions,
    progressBarOptions?: InitProgressBarOptions,
    params?: Params[],
    showDivider?: boolean
  ) => void;
}

const populateLink = (steps: ProgressBarStep[], params: Params[]) => {
  return steps.map((item) => {
    const paramsObject = params.reduce((acc: any, param) => {
      acc[param.key] = param.value;
      return acc;
    }, {});
    if (!item.link) {
      return item;
    }
    const link = buildLocalUrl({ path: item.link, params: paramsObject });
    return { ...item, link };
  });
};

export const useHeaderStore = create<HeaderState>()((set, get) => ({
  steps: [],
  activeStep: 0,
  allowAction: true,
  mainTitle: { label: "", text: "" },
  subTitleItems: [],
  signalIndicator: { heading: undefined, validation: { signalStrength: {} } },
  showMenu: true,
  showBackButton: false,
  showOptionsMenu: false,
  progressBarType: ProgressBarStyle.LINEAR_PROGRESS,
  showDivider: true,
  initialize: (
    headerOptions,
    progressBarOptions = { steps: [] },
    params = [],
    showDivider = true
  ) => {
    headerOptions.signalIndicator ??= undefined;
    set({ showDivider });
    get().initializeHeader(headerOptions);
    if (progressBarOptions.steps.length > 0) {
      get().initializeProgressBar(progressBarOptions, params);
    }
  },
  initializeProgressBar: (options, params = []) =>
    set((state) => {
      const steps = populateLink(options.steps, params);
      return { ...state, ...options, steps };
    }),
  initializeHeader: (options) => set((state) => ({ ...state, ...options })),
  setAllowAction: (allowAction) => set({ allowAction }),
  setStepStatus: (stepId, status) =>
    set((state) => {
      const steps = state.steps.map((step, index) => {
        if (index === stepId) {
          return { ...step, status };
        }
        return step;
      });
      return { ...state, steps };
    }),
  setActiveStep: (activeStep) => set({ activeStep }),
  populateLinks: (params: Params[]) =>
    set((state) => {
      const steps = populateLink(state.steps, params);
      return { ...state, steps };
    }),
  setShowBackButton: (showBackButton) => set({ showBackButton }),
  setShowOptionsMenu: (showOptionsMenu) => set({ showOptionsMenu }),
  setProgressBarType: (progressBarType) => set({ progressBarType }),
}));
